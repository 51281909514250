import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { AvatarItem } from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';

import { ExternalCollaboratorUserLozenge } from '@confluence/external-collab-ui';
import { ProfileAvatar, ProfileCardWrapper } from '@confluence/profile';
import { fg } from '@confluence/feature-gating';

import type { Account } from '../../../hooks';
import { AccountType } from '../../../hooks';
import { getLastViewedText } from '../../analyticsUtils';
import { AvatarSkeleton, PrimaryTextSkeleton, SecondaryTextSkeleton } from '../LoadingSkeleton';
import { EngagementCell } from '../../PageAnalytics/EngagementCell';

import * as S from './userListStyled';

const i18n = defineMessages({
	anonymous: {
		id: 'confluence-analytics.dialog.user-list.anonymous-user-name',
		defaultMessage: 'Anonymous',
		description: 'Text for an anonymous user in a list of users.',
	},
	deactivated: {
		id: 'confluence-analytics.dialog.user-list.deactivated-user-label',
		defaultMessage: 'Deactivated',
		description: 'Label indicating that a user is no longer active.',
	},
});

const EngagementLozengeSkeletonStyles = xcss({
	width: '80px',
	height: '24px',
	backgroundColor: 'color.skeleton',
});

export type AnalyticsDialogUserListRowProps =
	| {
			isLoading: true;
			account?: undefined;
	  }
	| {
			isLoading: false;
			account: Account;
	  };

export const AnalyticsDialogUserListRow = ({
	isLoading,
	account,
}: AnalyticsDialogUserListRowProps) => {
	const intl = useIntl();

	const displayName = useMemo(() => {
		if (isLoading) {
			return null;
		}
		if (account.accountType === AccountType.ANONYMOUS) {
			return intl.formatMessage(i18n.anonymous);
		}
		if (account.isDeactivated) {
			return `${account.name} (${intl.formatMessage(i18n.deactivated)})`;
		}
		return account.name;
	}, [account?.accountType, account?.isDeactivated, account?.name, intl, isLoading]);

	const secondaryText = useMemo(() => {
		if (isLoading) {
			return null;
		}
		if (!account?.lastViewedAt) {
			return null;
		}
		return getLastViewedText(new Date(account.lastViewedAt), intl);
	}, [account?.lastViewedAt, intl, isLoading]);

	const engagementLozenge = useMemo(() => {
		if (isLoading) {
			return null;
		}
		return <EngagementCell isEngaged={account?.isEngaged ?? null} />;
	}, [account?.isEngaged, isLoading]);

	const ariaLabel = useMemo(() => {
		const ariaLabelParts = [displayName, secondaryText].filter(Boolean);
		if (ariaLabelParts.length === 0) {
			return undefined;
		}
		return ariaLabelParts.join(', ');
	}, [displayName, secondaryText]);
	const name = displayName ?? '';

	return fg('confluence_engaged_views') ? (
		<S.UserListRow aria-label={ariaLabel}>
			<S.UserContainer>
				<AvatarItem
					avatar={
						isLoading ? (
							<AvatarSkeleton />
						) : (
							<ProfileAvatar
								userId={account.id}
								src={account.avatarURL ?? ''}
								isAnonymous={account.accountType === AccountType.ANONYMOUS}
								canViewProfile
								size="small"
								name={name}
							/>
						)
					}
					primaryText={
						isLoading ? (
							<PrimaryTextSkeleton />
						) : (
							<ProfileCardWrapper
								userId={account.id}
								isAnonymous={account.accountType === AccountType.ANONYMOUS}
								canViewProfile
							>
								<>
									<span>{displayName}</span>{' '}
									{account.accountType === AccountType.GUEST && <ExternalCollaboratorUserLozenge />}
								</>
							</ProfileCardWrapper>
						)
					}
					secondaryText={isLoading ? <SecondaryTextSkeleton /> : secondaryText}
				/>
				{isLoading ? (
					<Box
						xcss={EngagementLozengeSkeletonStyles}
						testId="analytics-dialog-engagement-lozenge-loading"
					/>
				) : (
					engagementLozenge
				)}
			</S.UserContainer>
		</S.UserListRow>
	) : (
		<S.UserListRow aria-label={ariaLabel}>
			<S.UserContainer>
				<AvatarItem
					avatar={
						isLoading ? (
							<AvatarSkeleton />
						) : (
							<ProfileAvatar
								userId={account.id}
								src={account.avatarURL ?? ''}
								isAnonymous={account.accountType === AccountType.ANONYMOUS}
								canViewProfile
								size="small"
								name={name}
							/>
						)
					}
					primaryText={
						isLoading ? (
							<PrimaryTextSkeleton />
						) : (
							<ProfileCardWrapper
								userId={account.id}
								isAnonymous={account.accountType === AccountType.ANONYMOUS}
								canViewProfile
							>
								<>
									<span>{displayName}</span>{' '}
									{account.accountType === AccountType.GUEST && <ExternalCollaboratorUserLozenge />}
								</>
							</ProfileCardWrapper>
						)
					}
				/>
				{isLoading ? (
					<S.SecondaryText>
						<SecondaryTextSkeleton />
					</S.SecondaryText>
				) : (
					<S.SecondaryText>{secondaryText}</S.SecondaryText>
				)}
			</S.UserContainer>
		</S.UserListRow>
	);
};
