import React, { useMemo } from 'react';
import type { FC } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import type { ButtonProps } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { N500 } from '@atlaskit/theme/colors';
import FocusRing from '@atlaskit/focus-ring';
import { token } from '@atlaskit/tokens';
import { Inline, xcss } from '@atlaskit/primitives';

import type { Pill } from '@atlassian/pill-selector';
import { PillSelector } from '@atlassian/pill-selector';

import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { fg } from '@confluence/feature-gating';

import { DialogPillName, TestId } from '../dialogConstants';

import * as S from './styled';

const i18n = defineMessages({
	dialogHeader: {
		id: 'confluence-analytics.dialog.header',
		defaultMessage: 'Analytics',
		description: 'Text to display in the dialog when there is no pills to show',
	},
	dialogCloseAriaLabel: {
		id: 'confluence-analytics.dialog.close-aria-label',
		defaultMessage: 'Close Confluence Analytics nonmodal dialog',
		description: 'Label for a button to close the analytics dialog.',
	},
	dialogCloseLabel: {
		id: 'confluence-analytics.dialog.close-label',
		defaultMessage: 'Close modal',
		description: 'Label for a button to close the analytics dialog.',
	},
	filterViewed: {
		id: 'confluence-analytics.filters.views',
		defaultMessage: 'Views',
		description: 'Name of the tab that shows a graph and list of who has viewed the page.',
	},
	filterMentioned: {
		id: 'confluence-analytics.filters.mentions',
		defaultMessage: 'Mentions',
		description: 'Name of the tab that shows a progress bar and list of mentioned users.',
	},
	filterLinks: {
		id: 'confluence-analytics.filters.links',
		defaultMessage: 'Links',
		description:
			'Name of the tab that shows a list of links that are inbound to or outbound from the current content.',
	},
});

const PillSelectorContainerStyles = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.100',
});

export type AnalyticsDialogHeaderProps = {
	error?: ApolloError;
	isLoading: boolean;
	selectedPill: DialogPillName;
	shouldShowPills: boolean;
	onPillSelected?: (pill: DialogPillName) => void;
	onClose?: ButtonProps['onClick'];
};

export const AnalyticsDialogHeader: FC<AnalyticsDialogHeaderProps> = ({
	error,
	isLoading,
	selectedPill,
	shouldShowPills,
	onPillSelected = () => {},
	onClose,
}) => {
	const intl = useIntl();
	const { edition } = useSessionData();

	const shouldShowLoadingState = useMemo(() => isLoading || !!error, [isLoading, error]);

	const closeButton = (
		<FocusRing>
			<S.CloseButton appearance="link" onClick={onClose} testId={TestId.CLOSE_BUTTON}>
				<CrossIcon
					spacing="spacious"
					label={intl.formatMessage(i18n.dialogCloseLabel)}
					color={token('color.icon', N500)}
				/>
			</S.CloseButton>
		</FocusRing>
	);

	const dialogPills: Pill<DialogPillName>[] = [
		{
			name: DialogPillName.VIEWS,
			content: <FormattedMessage {...i18n.filterViewed} />,
		},
		...(edition === ConfluenceEdition.PREMIUM
			? [
					{
						name: DialogPillName.MENTIONS,
						content: <FormattedMessage {...i18n.filterMentioned} />,
					},
					...(fg('confluence_frontend_analytics_ui_links_view')
						? [
								{
									name: DialogPillName.LINKS,
									content: <FormattedMessage {...i18n.filterLinks} />,
								},
							]
						: []),
				]
			: []),
	];

	if (fg('confluence_frontend_analytics_ui_links_view')) {
		return (
			<>
				<Inline spread="space-between">
					<Heading size="medium" as="h3">
						<FormattedMessage {...i18n.dialogHeader} />
					</Heading>
					{closeButton}
				</Inline>
				<Inline xcss={PillSelectorContainerStyles}>
					<PillSelector
						isLoading={shouldShowLoadingState}
						pills={dialogPills}
						selectedPill={selectedPill}
						testId={TestId.PILLS}
						onPillSelected={onPillSelected}
					/>
				</Inline>
			</>
		);
	}

	if (shouldShowLoadingState) {
		return (
			<S.Header>
				<PillSelector
					isLoading
					pills={dialogPills}
					selectedPill={selectedPill}
					testId={TestId.PILLS}
					onPillSelected={onPillSelected}
				/>
				{closeButton}
			</S.Header>
		);
	}

	if (shouldShowPills) {
		return (
			<S.Header>
				<PillSelector
					isLoading={false}
					pills={dialogPills}
					selectedPill={selectedPill}
					testId={TestId.PILLS}
					onPillSelected={onPillSelected}
				/>
				{closeButton}
			</S.Header>
		);
	}

	return (
		<S.Header>
			<Heading size="medium" as="h2">
				<FormattedMessage {...i18n.dialogHeader} />
			</Heading>
			{closeButton}
		</S.Header>
	);
};
