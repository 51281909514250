import { styled } from '@compiled/react';

import { N0, N50A, N60A, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { borderRadius } from '@confluence/typography-placeholder';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	width: '400px',
	display: 'flex',
	padding: token('space.250', '20px'),
	flexDirection: 'column',
	boxSizing: 'border-box',
	position: 'relative',
	overflowX: 'hidden',
	backgroundColor: token('elevation.surface.overlay', N0),
	boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N50A}, 0 0 1px ${N60A}`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRContainer = styled.div<{ zIndex: number }>({
	position: 'absolute',
	display: 'none',
	marginTop: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: (props) => props.zIndex,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRChartSkeleton = styled.div({
	height: '68px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRLoadingText = styled.span({
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
	color: token('color.text.subtle', N200),
	margin: `${token('space.150', '12px')} 0px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DialogCenteredRow = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
