import { styled } from '@compiled/react';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ButtonProps } from '@atlaskit/button/standard-button';
import Button from '@atlaskit/button/standard-button';
import ChartTrendIcon from '@atlaskit/icon/core/chart-trend';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { xcss, Box } from '@atlaskit/primitives';

import { ChangeEditionSectionMessagePageInsights } from '@confluence/change-edition/entry-points/pageInsights';
import {
	ANALYTICS_DIALOG_UPSELL_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { expValEquals } from '@confluence/feature-experiments';

import { SSREvent } from '../../hooks';

import type { UseAnalyticsByLineHook } from './UseAnalyticsByLineHook';

const standardWrapperStyles = xcss({
	width: '350px',
});

const analyticsBylinePlatformStyles = xcss({
	paddingLeft: 'space.050',
	marginRight: 'space.100',
});

const popupContentExperience = ANALYTICS_DIALOG_UPSELL_EXPERIENCE;

export const useAnalyticsByLineUpsell: UseAnalyticsByLineHook = () => {
	const isLivePage = useIsCurrentPageLive();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const onPopupTriggerSSREvent = useCallback(
		(event: SSREvent) => {
			if (event === SSREvent.CLICK) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'pageInsightGate',
						source: 'pageInsights',
						attributes: {
							isSiteAdmin,
							isLivePage,
						},
					},
				}).fire();
			}
		},
		[createAnalyticsEvent, isSiteAdmin, isLivePage],
	);

	return {
		loading: false,

		//
		// Popup content
		//

		popupContent: () => (
			<Box as="section" xcss={standardWrapperStyles}>
				<ChangeEditionSectionMessagePageInsights>
					<ExperienceSuccess name={popupContentExperience} />
				</ChangeEditionSectionMessagePageInsights>
			</Box>
		),

		popupContentExperience,

		//
		// Popup trigger
		//

		popupTrigger: ({ onClick }) => (
			<PopupTrigger
				onClick={onClick}
				abbreviatePageViews={
					isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
				}
			/>
		),

		onPopupTriggerSSREvent,
	};
};

const i18n = defineMessages({
	byLineUpsellText: {
		id: 'confluence-analytics.byLineUpSell.buttonText',
		defaultMessage: 'See how many people viewed this',
		description: 'Analytics button text which would show how many people viewed a page',
	},
	byLineAbbreviatedUpsellText: {
		id: 'confluence-analytics.abbreviatedByLineUpSell.buttonText',
		defaultMessage: 'See views',
		description: 'Abbreviated analytics button text which would show how many people viewed a page',
	},
});

// Below Icon and Button styles are copied from @atlassian/analytics-byline to keep the styles consistent

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AnalyticsIconWrapper = styled.span({
	marginRight: token('space.050', '4px'),
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ByLineButtonWrapperWithDot = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '"•"',
		display: 'inline-block',
		color: token('color.text.subtle', N200),
		paddingRight: token('space.100', '8px'),
		/* for IE <= 11 */
		textDecoration: 'none',
	},
});

type PopupTriggerProps = Pick<ButtonProps, 'onClick'> & {
	abbreviatePageViews?: boolean;
};

const PopupTrigger = (props: PopupTriggerProps) => {
	const { abbreviatePageViews, onClick } = props;
	const analyticsBylineUpsellMessage = abbreviatePageViews
		? i18n.byLineAbbreviatedUpsellText
		: i18n.byLineUpsellText;
	const BylineButtonWrapper = abbreviatePageViews ? Box : ByLineButtonWrapperWithDot;
	/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */
	const AnalyticsBylineUpsellIcon = abbreviatePageViews ? ChartTrendIcon : GraphLineIcon;

	return (
		<BylineButtonWrapper {...(abbreviatePageViews && { xcss: analyticsBylinePlatformStyles })}>
			<Button
				onClick={onClick}
				appearance="subtle-link"
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ fontWeight: 'unset' }}
				spacing="none"
			>
				<AnalyticsIconWrapper aria-hidden="true">
					<AnalyticsBylineUpsellIcon label="" size="small" />
				</AnalyticsIconWrapper>
				<FormattedMessage {...analyticsBylineUpsellMessage} />
			</Button>
		</BylineButtonWrapper>
	);
};
